.add-app-container {
  width: 100%;
  flex-direction: column;
}

.submit-box {
  width: 42rem;
  max-width: 42rem;
  margin-top: 2rem;
}

.btn-add-prompt {
  margin-top: 1rem;
}

.form-label {
  max-width: 40rem;
}
@media only screen and (max-width: 992px) {
  main.content {
    width: 90%;
  }
  .submit-box {
    margin-top: 0rem;
  }
  .form-box {
    padding: 0rem;
  }
}
