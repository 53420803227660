.storybook-button {
  font-family: 'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 700;
  border: 0;
  border-radius: 3em;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
}

.storybook-button svg {
  margin-left: 0.3rem;
}
.storybook-button--primary {
  color: white;
  background-color: #f13149;
}
.storybook-button--secondary {
  color: #333;
  background-color: transparent;
  box-shadow: rgb(0 0 0 / 15%) 0 0 0 1px inset;
}

.storybook-button--highlight {
  background-image: linear-gradient(
    to bottom,
    #ee2079 0%,
    #f54d4d 51%,
    #fc7c1f 100%
  );
  color: white;
}

.storybook-button--tertiary {
  color: #e5989b;
  background-color: #000;
  box-shadow: rgb(0 0 0 / 15%) 0 0 0 1px inset;
}

.storybook-button--lighterBg {
  color: #fff;
  background-color: #ffb4a2;
  box-shadow: rgb(0 0 0 / 15%) 0 0 0 1px inset;
}
.storybook-button--small {
  font-size: 12px;
  padding: 4px 8px;
}
.storybook-button--medium {
  font-size: 14px;
  padding: 11px 20px;
}
.storybook-button--large {
  font-size: 16px;
  padding: 12px 24px;
}

.gradient {
  background-image: linear-gradient(
    to right,
    #ed1a7e 0%,
    #f54e4b 51%,
    #fd811a 100%
  );
}

.gradient:hover {
  background-position: right center;
}

.form-absolute {
  position: absolute;
}
