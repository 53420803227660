.navigation {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem 1.5rem 2rem;
  align-items: center;
}

.menu ul,
.nav-buttons ul {
  display: flex;
  align-items: center;
  padding: 0rem;
  margin: 0rem;
}

ul li,
.menu ul li {
  list-style-type: none;

  color: black;
}

@media only screen and (min-width: 769px) {
  ul li:not(.dropdown-search ul li):not(.dropdown-search-modal ul li),
  .menu ul li {
    margin-right: 1.5rem;
  }
}

.dropdown-search-modal ul {
  padding: 0 !important;
}

.nav-buttons ul li {
  list-style-type: none;
  margin-right: 1.5rem;
  color: black;
}

.navigation .menu ul > li:nth-child(1) {
  font-weight: 700;
}

.nav-buttons ul > li:nth-child(3) {
  margin-right: 0rem;
}

.nav-link {
  color: black;
}
.container-logged-in {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.container-logged-out {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-direction: row-reverse;
}

.navigation-mobile {
  display: none;
}

.hamburger-menu-button.close {
  /* min-width: 3.5rem; */
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #ccc;
}

.hamburger-menu-button.storybook-button svg {
  margin: 0 !important;
}
.hamburger-menu-button.close.storybook-button svg {
  margin-left: -0.3rem !important;
}

.hamburger-menu.menu-closed,
.menu-user.menu-closed {
  display: none;
}
.hamburger-menu.menu-open,
.menu-user.menu-open {
  display: block;
  position: absolute;
  z-index: 999;
  background: white;
  max-width: 100%;
  top: 5rem;
  right: 0px;
  /* bottom: 0px; */
  left: 0px;
  height: 100%;
  padding: 1rem;
  border-bottom: 1px solid #c8a2aa;
}

.menu-user.menu-open {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.hamburger-menu li {
  margin-bottom: 1.5rem;
}

.navigation-mobile .menu ul {
  justify-content: space-between;
}

.navigation-mobile .img-logo {
  padding: 0;
  max-width: 4.5rem;
  margin-top: 0.3rem;
}

.navigation-mobile {
  margin: 0.75rem 0.5rem;
}

@media only screen and (max-width: 768px) {
  .container-logged-in {
    flex-direction: row;
    gap: 1rem;
  }
  .navigation-mobile {
    display: block;
    margin: 0.75rem 0.5rem 2rem;
  }
  .navigation {
    display: none;
  }
  .input-search-modal {
    min-width: 19rem !important;
  }
  .dropdown-search-modal {
    min-width: 19rem !important;
    max-width: 19rem !important;
  }
  .modal-close-btn {
    top: -0.5rem;
    right: -0.5rem;
  }
}

.submit {
  font-weight: bold;
  color: #000 !important;
}

.input-search-navigation {
  border: 1px solid #d9e1ec !important;
  min-width: 15rem !important;
  padding: 1rem 1.5rem 1rem 2rem !important;
  border-radius: 5px !important;
}

.dropdown-search-modal {
  position: relative;
  background-color: #fff;
  border: 1px dashed #946b54 !important;
  border-radius: 5px;
  min-width: 28rem;
  max-width: 28rem;
}

.dropdown-search-modal ul li {
  margin-bottom: 1rem;
}

.search-icon-modal {
  position: absolute;
  padding: 1rem;
}

.img-logo {
  max-width: 14rem;
  max-height: 6rem;
  padding-top: 1rem;
}

.input-search-modal {
  min-width: 28rem;
  border: 1px solid #d9e1ec !important;
  padding: 1rem 1.5rem 1rem 2.5rem !important;
  border-radius: 5px !important;
  height: 3rem !important;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  top: 4.75rem;
  right: 2rem;
  background-color: #fff;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 0.5rem;
}

/* Links inside the dropdown */
.dropdown-content a,
.dropdown-content div {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  cursor: pointer;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover,
.dropdown-content div:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.container-logged-in:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown-content:hover .dropdown-content {
  display: block;
}

.div-logout {
  border-top: 1px solid #ddd;
}
.logo-nav-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 11rem;
}
