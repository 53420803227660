.user-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: brown;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 50%;
  font-weight: 800;
}
